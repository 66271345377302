/**
 * Search Autocomplete
 * @description : Search Autocomplete feature
 */

// The main Vue instance to be used in the Header
var searchApp = new Vue({
    el: '#search-app',

    data: {
        loading: false, // Initial loading state
        refSearchField: '',
        searchTerm: '',
        searchResults: [],
        displayResultList: false,
        displayClearBtn: false,
        formActionUrl: ''
    },
    methods: {
        onKeyUpSearch: _.debounce(function (ref, event) {
            // console.log(event.target.value);
            this.searchTerm = event.target.value;
            this.fetchSearchSuggestion();
        }, 500),

        fetchSearchSuggestion: function () {
            this.searchResults = [];
            // console.log('request data', this.searchTerm);

            axios({
                    method: 'get',
                    url: `/api/autocomplete/get?letters=${this.searchTerm}`
                })
                .then(response => {
                    this.searchResults = response.data;
                    this.loading = false;
                    // console.log('results data', response.data);
                })
                .catch(error => {
                    this.errored = true;
                });
        },
        updateInputValue: function (value, event) {
            event.stopPropagation();
            this.searchTerm = value;
            this.displayResultList = false;
            document.getElementById('query').value = value;
            document.getElementById('searchform').submit();
        },
        clearSearchField: function (event) {
            event.stopPropagation();
            this.searchTerm = '';
            this.fetchSearchSuggestion();
        }
    },
    beforeMount: function () {
        var _self = this;
        _self.formActionUrl = _self.$el.getAttribute('data-search-page-url');
    },
    mounted: function () {
        var _self = this;

        setTimeout(() => {
            _self.$el.classList.add('is-loaded');
            _self.refSearchField = _self.$refs.search_field;
        }, 1000);
    },
    watch: {
        searchResults: function (newValue) {
            if (newValue.length > 0) {
                this.displayResultList = true;
            } else {
                this.displayResultList = false;
            }
        },
        searchTerm: function (newValue) {
            if (newValue !== '') {
                this.displayClearBtn = true;
            } else {
                this.displayClearBtn = false;
            }
        }
    },
    template: `
        <div>
            <form class="c-mini-form  c-mini-form--solid  c-mini-form--lg" id="searchform" method="get" v-bind:action="formActionUrl">
                <div class="c-mini-form__textbox-wrap" id="js-search-autocomplete">
                    <input class="c-mini-form__textbox  js-search-tray__textbox" type="search" v-on:keyup="onKeyUpSearch(refSearchField, $event)" v-model="searchTerm" ref="search_field" name="query" id="query" placeholder="Search" aria-label="Search" autocomplete="off">
                </div>
                <div class="c-mini-form__submit-wrap">
                    <div class="c-mini-form__submit-icon-button  c-icon-button  c-icon-button--encircled  u-bg-vrt-purple-to-pink">
                        <button class="c-icon-button__button" type="submit" title="Submit">
                            <span class="u-visually-hidden">Submit</span>
                            <svg class="c-icon  c-icon--search  c-icon-button__icon" width="19.036" height="18.996" viewBox="0 0 19.036 18.996">
                                <path d="M18.4 5.029a8.134 8.134 0 0 0-1.765-2.62A8.436 8.436 0 0 0 14.007.648a8.28 8.28 0 0 0-6.422 0 8.473 8.473 0 0 0-2.624 1.76 8.271 8.271 0 0 0-1.773 2.618 8.234 8.234 0 0 0-.135 6.083 8.693 8.693 0 0 0 1.121 2.059L.318 17.079a1.135 1.135 0 0 0 0 1.579 1.111 1.111 0 0 0 1.6 0l3.825-3.88a8.4 8.4 0 0 0 2.108 1.184 8.3 8.3 0 0 0 6.153-.113 8.487 8.487 0 0 0 2.625-1.761 8.179 8.179 0 0 0 2.4-5.838 8.2 8.2 0 0 0-.629-3.221Zm-1.585 3.217a5.83 5.83 0 0 1-.472 2.339 6.248 6.248 0 0 1-1.291 1.921 5.994 5.994 0 0 1-8.511 0 6.26 6.26 0 0 1-1.293-1.921 6.029 6.029 0 0 1 0-4.677A6.287 6.287 0 0 1 6.54 3.987a6.055 6.055 0 0 1 1.916-1.3 6.033 6.033 0 0 1 4.681 0 6.027 6.027 0 0 1 1.915 1.3 6.275 6.275 0 0 1 1.292 1.921 5.841 5.841 0 0 1 .471 2.338Z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="c-mini-form__line  u-bg-hrz-green-to-teal"></div>
            </form>

            <ul class="c-autocomplete-list  u-mt-4" v-if="displayResultList">
                <li class="c-autocomplete-list__item" v-for="item in searchResults" :item="item" :key="item">
                    <button class="c-autocomplete-list__button" type="button" v-on:click="updateInputValue(item, $event)">
                        {{item}}
                    </button>
                </li>
            </ul>
        </div>
    `
});

if (document.querySelector('#search-page-app')) {
    
// The secondary Vue instance to be used in the Search Page
var searchPageApp = new Vue({
    el: '#search-page-app',

    data: {
        loading: false, // Initial loading state
        refSearchField: "",
        searchTerm: '',
        searchResults: [],
        displayResultList: false,
        displayClearBtn: false,
        formActionUrl: ''
    },
    beforeMount: function () {
        var _self = this;
        _self.formActionUrl = _self.$el.getAttribute('data-search-page-url');
    },
    methods: {
        onKeyUpSearch: _.debounce(function (ref, event) {
            // console.log(event.target.value);
            this.searchTerm = event.target.value;
            this.fetchSearchSuggestion();
        }, 500),

        fetchSearchSuggestion: function () {
            this.searchResults = [];
            // console.log('request data', this.searchTerm);
            axios({
                    method: 'get',
                    url: `/api/autocomplete/get?letters=${this.searchTerm}`
                })
                .then(response => {
                    this.searchResults = response.data;
                    this.loading = false;
                    // console.log('results data', response.data);
                })
                .catch(error => {
                    this.errored = true;
                });
        },
        updateInputValue: function (value, event) {
            event.stopPropagation();
            this.searchTerm = value;
            this.displayResultList = false;
        },
        clearSearchField: function (event) {
            event.stopPropagation();
            this.searchTerm = '';
            this.fetchSearchSuggestion();
        }
    },
    mounted: function () {
        var _self = this;

        setTimeout(() => {
            _self.$el.classList.add('is-loaded');
            _self.refSearchField = _self.$refs.search_field;
        }, 1000);
    },
    watch: {
        searchResults: function (newValue) {
            if (newValue.length > 0) {
                this.displayResultList = true;
            } else {
                this.displayResultList = false;
            }
        },
        searchTerm: function (newValue) {
            if (newValue !== '') {
                this.displayClearBtn = true;
            } else {
                this.displayClearBtn = false;
            }
        }
    },
    template: `
        <div class="u-mw-7">
            <form class="c-mini-form" id="searchform" method="get" v-bind:action="formActionUrl">
                <div class="c-mini-form__textbox-wrap" id="js-search-autocomplete">
                    <input class="c-mini-form__textbox  js-search-tray__textbox" type="search" v-on:keyup="onKeyUpSearch(refSearchField, $event)" v-model="searchTerm" ref="search_field" name="query" id="query" placeholder="Search" aria-label="Search" autocomplete="off">
                </div>
                <div class="c-mini-form__submit-wrap">
                    <button class="c-mini-form__submit" type="submit" title="Submit">
                        <span class="c-mini-form__submit-text">
                            <span class="u-font-neue-machina-nudge-up">Search</span>
                        </span>
                    </button>
                </div>
            </form>

            <ul class="c-autocomplete-list  u-mt-4" v-if="displayResultList">
                <li class="c-autocomplete-list__item" v-for="item in searchResults" :item="item" :key="item">
                    <button class="c-autocomplete-list__button" type="button" v-on:click="updateInputValue(item, $event)">
                        {{item}}
                    </button>
                </li>
            </ul>
        </div>
`
});

}